.fixo{
    width: 200px !important;
}
table {
    table-layout: fixed;
    width: 100%;
    border: 1px solid #f00;
    word-wrap: break-word;
}
.fixoHora{
    width: 90px !important;
}