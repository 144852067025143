.circulo {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0px 80px 20px 50px;
    float: left;

}

.aprovado {
    background-color: #058700;
}

.em-aprovacao {
    background-color: #676a6c;
}

.rejeitado {
    background-color: red;
}

.legenda {
    margin-left: 20px;
    white-space: nowrap
}

.group {
    clear: both;
}

.div-collapse {
    position: relative;
}

.button-link {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}

.desativado{
    pointer-events: none;
}