iframe {
  border: none;
}

.home {
  background-color: white;
}
.swal2-container {
  zoom: 1.5;
}

#circulo1 {
  background: radial-gradient(circle closest-side,#f00 0%,#f00 98%,rgba(0,0,0,0) 100%);
}

.react-datepicker {
  zoom: 1.2;
}

.divData{
  margin-left: 15px;
  margin-bottom: 20px;
}

.data{
  width: 130px;
}

.btn-filtrar-data,
.btn-filtrar-data,
.btn-filtrar-data:hover,
.btn-filtrar-data:focus {
  margin-top:25px;
  background-color: #720425;
  color: white;
}

.btn-filtrar-data-selected,
.btn-filtrar-data-selected,
.btn-filtrar-data-selected:hover,
.btn-filtrar-data-selected:focus {
  margin-top:25px;
  background-color: #c5053f;
  color: white;
}

@media only screen and (max-width: 600px) {
	#panel {
		width: 100%;
		margin: 10px;
	}
}