iframe {
	border: none;
}

.home {
	background-color: white;
}
.swal2-container {
	zoom: 1.5;
}
.react-datepicker {
	zoom: 1.2;
}
.mr15 {
	margin-right: 15px;
}
.mr25 {
	margin-right: 25px;
}
.p15 {
	padding: 2px;
	background-color: white;
}
.btn-gerar,
.btn-gerar:hover,
.btn-gerar:focus {
	background-color: #720425;
	color: white;
}
.astericoVermelho {
	color: red;
}

.relatorios {
	width: 95%;
	margin: 40px auto 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.box-relatorios {
	width: 25%;
	background: #720425;
	border-radius: 5px;
	color: white;
	margin-right: 20px;
	text-align: center;
	padding: 10px;
	box-sizing: border-box;
	transition: all 0.5s;
	font-size: 15px;
}

.box-relatorios:hover {
	cursor: pointer;
	width: 30%;
	font-size: 20px;
	transition: all 0.5s;
	color: white;
}

.row-relatorios {
	text-align: center;
}
