.circulo {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0px 80px 20px 50px;
    float: left;
}

.aprovado {
    background-color: #058700;
}

.em-aprovacao {
    background-color: #676a6c;
}

.rejeitado {
    background-color: red;
}

.legenda {
    margin-left: 20px;
    white-space: nowrap
}

.group {
    clear: both;
}

.div-collapse {
    position: relative;
}

.zoom15{
    /* zoom: 1.5; */
    /* color: blue; */
    padding-left: 5px;
}

.fixo0{
    width:50px;
}

.fixo1{
    width:100px;
}

.fixo2{
    width:200px;
}

.input-search{
    width:150px;
    margin: 0 30px 30px 0;
}

.orderby-div
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.orderby-div label
{
    display: block;
    width:150px;
}