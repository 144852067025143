.mt15 {
	margin-top: 15px;
}
.mt-0 {
	margin-top: 0;
}

hr.mt-0 {
	margin-bottom: 10px;
}
