.retangulo_ {
    width: 10px;
    height: 20px;
    margin: 0px 0px 5px 15px;
    float: left;
    clear: left;
}

.legenda_ {
    margin-left: 20px;
    white-space: nowrap;
    text-transform: uppercase;
}

.txt {
    background-color: #929aa2;
}

.csv1 {
    background-color: #4a58bd;
}

.csv2 {
    background-color: #6fd458;
}

.button-retangulo {
    width: 10px;
    height: 20px;
    margin: 0px 10px 10px 0px;
    float: left;
    color:inherit;
    border:none; 
    padding:0!important;
    font: inherit;
    /*border is optional*/
    cursor: pointer;
}