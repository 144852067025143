.col-container
{
    width:97%;
    margin: 0 auto;
}

.checkbox-div
{
    display:flex;
    margin-right: 20px;
    margin-top:27px;
}

.btn-cadastro
{
    background-color: #720425;
    color:white;
    margin-right:15px;
}

.errorMessage, .asteric
{
    color:red;
}


table
{
    margin-top:15px;
}

table tr th
{
    background-color:#720425;
    color:white;
    text-align: center;
}

.btn-editar:hover
{
    cursor: pointer;
}

.table3
{
    margin-left:-3px;
    width:100%;
}

.colunaCol{width:25%;}
.colunaEmail{width:25%;}
.colunaLogin{width:25%;}
.colunaMat{width:15%;}
.colunaGrupo{width:20%;}
.colunaPerfil{width:20%;}
.colunaNOC{width:15%;}
.colunaApt{width:22%;}
.colunaEditar{width:11%;}
.colunaAti{width:15%;}

.icon-col
{
    margin-top: 9px !important;
}

.icon-col2
{
    margin-top: 1px !important;
}

.carregar-div
{
  text-align: center;
}

.btn-carregar
{
  color: white;
  background: #720425;
  padding-left:60px;
  padding-right:60px;
}

.perm-title
{
  display: block;
}

.permissoes
{
  margin-bottom:20px;
}

.perm-boxes
{
  display: flex;
}

.todos
{
  display: flex;
}

.todos input[type="checkbox"]
{
  display: flex;
  margin-left:2px;
}

.perm-box
{
  display: flex;
  flex-direction: column;
  margin-right:15px;
}

.perm-box2
{
  display: flex;
  margin-right:15px;
  justify-content: space-between;
}


.perm-box input[type="checkbox"]
{
  margin-left:2px;
  border-radius:20px;
}


.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 12px;
  margin-top:10px;
}

.switch2 {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 12px;
  margin:5px 0 0 5px;

}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch2 input { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  top:15px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: red;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 1px;
  bottom: 1.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: green;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.todos label
{
  margin-right:121px;
}


  


