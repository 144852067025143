.cadastro
{
    width:95%;
    margin:40px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box
{
    width:25%;
    height:250px;
    background:#720425;
    border-radius: 5px;
    color:white;
    margin-right: 20px;
    text-align: center;
    box-sizing: border-box;
    transition: all 1s ease-in-out;
    font-size:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:15px;
}

.box:hover
{
    cursor: pointer;
    width: 30%;
    height: 300px;
    /* height: 100px; */
    font-size:30px;
    color:white;
}

.box p
{
    font-weight: bold;
}

/* p, i
{
    color:white;
} */





.row-cadastro
{
    text-align: center;
}