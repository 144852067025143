.fixo1 {
	width: 100px;
}

.fixo2 {
	width: 200px;
}

.input-search {
	border: none;
	margin: 0;
	width: 100%;
}

.pesquisar-div {
	border: 2px solid #ccc;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 15px;
}

.edit-icon {
	zoom: 2;
	cursor: pointer;
	color: #8f8fd8;
}

.non-edit-icon {
	zoom: 2;
	cursor: not-allowed;
	color: #ccc;
}

.load-more-prj {
	display: flex;
	padding: 10px;
	justify-content: center;
}

.td-gerenciamento {
	vertical-align: middle !important;
}

.table {
	border: 1px solid #ccc;
}

.switch-t {
	height: 14px !important;
}

.inactive-slider-round {
	background-color: rgb(113, 148, 113) !important;
}

.inactive-slider-round:hover {
	cursor: not-allowed;
}
