/* Neste arquivo coloque apenas layouts globais. Para layouts de componentes comuns, crie um novo arquivo nesta pasta */
/* Para layouts de componentes unicos, criar arquivo na mesma pasta do componente */
/* Criado por Carlos Eduardo em 03/01/2019 */
/* Em caso de dúvidas: contato.carlos@outlook.com */
/* Acresentado Estilos dos inputs 22/01/2019 por Leandro Paranhos*/

.wrapper {
  width: 100% !important;
  overflow-x: hidden !important;
  padding: 0;
}

#page-wrapper {
  padding-bottom: 80px;
  padding-right: 0px;
  padding-left: 0px;
}

.container {
  padding-left: 30px;
}

body {
  background-color: #F3F3F4;
}

.float-right {
  float: right;
}

.th-black {
  background-color: #720425 !important;
  border: 0px !important;
  color: white;
}

table.table-add-edit {
  width: 90% !important;
}

.mini-th-100 {
  width: 100px;
}

#main.container-fluid {
  padding-bottom: 50px;
}

.nav-header {
  background: #720425;
  background: -webkit-linear-gradient(to bottom #720425, #4c1928);
  background: linear-gradient(to right, #720425, #4c1928);
}

.navbar-static-side{
  background: #4c1928;
  background: -webkit-linear-gradient(to bottom #720425, #4c1928);
  background: linear-gradient(to right, #720425, #4c1928);
}

.nav > li.active{
  background: #720425 !important;
}

.navbar-default .nav > li > a:hover, .navbar-default .nav > li > a:focus{
  background: #4c1928;
}

#info{
  vertical-align: top;
  padding-left: 5px;
}

/* Estilo dos inputs usados na tela Cdr Dados / SMS*/
.form-style-8 input[type="text"],
.form-style-8 input[type="date"],
.form-style-8 input[type="datetime"],
.form-style-8 input[type="email"],
.form-style-8 input[type="number"],
.form-style-8 input[type="search"],
.form-style-8 input[type="time"],
.form-style-8 input[type="url"],
.form-style-8 input[type="password"],
.form-style-8 textarea,
.form-style-8 select,
.form-style-8 option
{
	outline: none;
	display: inline-block;
	width: 19%;
  padding: 7px;
	border: none;
	border-bottom: 1px solid #ddd;
	background: transparent;
  margin-bottom: 10px;
  margin-left: 15px;
	font: 16px Arial, Helvetica, sans-serif;
  height: 45px;
}

.form-style-8 input[type="text"]:focus,
.form-style-8 select:focus {
  border-bottom: 2px solid #720425;
}

.form-style-8 input[type="text"]:focus:-ms-input-placeholder:focus { /* Internet Explorer 10-11 */
  color: #720425;
  font-weight:bold;
  font-size: 6px;
 }

.form-style-8 input[type="text"]:focus::-ms-input-placeholder:focus { /* Microsoft Edge */
  color: #720425;
  font-weight:bold;
 }

.form-style-8 input[type="text"]:focus::-webkit-input-placeholder { /* Chrome */
  color: #720425;
  font-weight:bold;
}

.form-style-8 input[type="text"]::-webkit-input-placeholder { /* Chrome */
  font-size: 15px;
}

.breadcrumb {
  padding: 8px 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  list-style: none;
  background-color: white;
  color: #656565;
  border-radius: 4px;
}

.breadcrumb > li {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 20px;
}

.breadcrumb > li + li::before {
  padding: 0 5px;
  color: #656565;
  content: "/\A0";
}

.breadcrumb > .active {
  color: #b6b6b6;
}

input.circles {
  position:relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  width:40px;
  height:40px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  margin:8px;
  background-color: #e7e7e7;
  outline: none;
  cursor: pointer;
}

.circles.green {
  background-color: #1ab394;
}

.circles.red {
  background-color: #ed5565;
}

label.button-state {
  vertical-align: top;
  margin-top: 20px;
  margin-right: 5px;
  width: 70px;
}

.breadcrumb > li{
  margin-top: 4px !important;
  font-size: 13px !important;
}

.modal-apontamento-detalhado-projeto {
  width: 70vw;    /* Occupy the 90% of the screen width */
  max-width: 90vw;
} 

.modal-apontamento-detalhado-projeto > h2{
  font-weight: bold;
}

.input-detalhado-projeto{
  width: 130px;
}

.btn-modal-apontamento-detalado-projeto{
  margin-right: 10px;
  font-size: 15px;
}
.primary-color{
  background-color: #720425;
  color: #F3F3F4;
}

.primary-color:hover{
  background-color: #a60535;
  color: #F3F3F4;
}