.btn-cadastro
{
    background-color: #720425;
    color:white;
    margin-right:15px;
}

.btn-cadastro:hover, .btn-cadastro:focus, .btn-carregar:focus, .btn-carregar:hover
{
    background-color: #720425;
    color:white;  
}

.carregar-div
{
  text-align: center;
}

.btn-carregar
{
  color: white;
  background: #720425;
  padding-left:60px;
  padding-right:60px;
}

.form-prj
{
    width:100%;
}

.form-prj2
{
    width:100%;
}

.btn-novoProjeto
{
    margin-top:22px;
}

.readOnlyInput
{
    background: white !important;
}

.headingTitle
{
    margin-bottom: 15px;
}

.containerSalvarLimpar
{
    margin-left:15px;
}

.required, .obrigatorio
{
    color:red;
}

table
{
    margin-top:15px;
}

table tr th
{
    background-color:#720425;
    color:white;
    text-align: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 12px;
  margin-top:10px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: red;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 1px;
  bottom: 1.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: green;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

  .table2
  {
      margin-left:-3px;
      width:90%;
  }

  .btn-editar:hover
  {
    cursor:pointer;
  }

  .btn-deletar:hover
  {
    cursor:pointer;
  }

.filtro{
  float:left;
  width: 80%;
  text-align: center;
  margin-top:50px;
  margin-left: 55px;
}

.colunaCliente, .colunaAti
{
  width: 15%;
}

.colunaCodPrj, .colunaCodSubprj
{
  width:20%;
}

.colunaPrj, .colunaSubprjNat
{
  width:30%;
}

.colunaApv
{
  width:25%;
}

.colunaEdit
{
  width:10%;
}

.colunaDelete
{
  width:10%;
}

@media(max-width:756px)
{
  .novoProjeto
  {
    width:92%;
    padding-top: 0;
    margin:-12px auto 20px auto;
  }

  .novoProjeto button 
  {
    margin-top:2px;
  }

  .codigoSub
  {
    width:89%;
    margin:0 auto;
    padding: 0;
  }

  .codigoSub input
  {
    width:100%;
  }

  .filtro
  {
    width:100%;
  }

  .filtro input 
  {
    width:100%;
  }

  .filtro label
  {
    display: block;
    text-align: left;
    margin-bottom: 0;
  }

  .filtro button 
  {
    margin-top: 10px;
    margin-bottom: 30px;
    float: left;
  }
  
  .carregar-div
  {
    margin-top:20px;
    margin-bottom:20px;
  }

  table
  {
    margin-top:0;
  }

  .colunaCliente, .colunaAti
  {
    width: 80px;
  }

  .colunaCodPrj, .colunaCodSubprj
  {
    width:200px;
  }

  .colunaPrj, .colunaSubprjNat
  {
    width:400px;
  }

  .colunaApv
  {
    width:200px;
  }

  .colunaEdit
  {
    width:60px;
  }

  .table2
  {
    width: 100%;
    word-break: break-all;
  }
}






 


