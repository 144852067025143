.input-horas-projeto {
	width: 50% !important;
	font-size: large !important;
	padding: 20px 10px !important;
}

.descricao_alteracao {
	padding: 10px 10px !important;
}

.btn-salvar-edicao {
	border: 0.2rem solid #8c1818;
	background-color: #8c1818;
	color: #fff;
	width: 18rem;
	border-radius: 0.8rem;
	margin-right: 3rem;
	padding: 1rem 0;
}

.btn-salvar-edicao:hover,
.btn-salvar-edicao:active,
.btn-salvar-edicao:visited,
.btn-salvar-edicao:focus {
	color: #fff !important;
}

.btn-cancelar-edicao {
	border: 0.2rem solid black;
	background-color: #fff;
	color: black;
	width: 18rem;
	border-radius: 0.8rem;
	margin-right: 3rem;
	padding: 1rem 0;
}

.btn-cancelar-edicao:hover {
	color: black;
}

.buttom-group-editar-horas-projeto {
	margin-top: 5rem;
	margin-bottom: 5rem;
}
