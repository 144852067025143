.asteric
{
    color:red;
}
.erro
{
    color:red;
    padding: 0;
    margin: 0;
}
.text15 {
    font-size: 15px!important;
}

.btn-modal,
.btn-modal:hover,
.btn-modal:focus {
    background-color: #720425;
    color: white;
}
.input-mask{
    font-size: 14px !important;
    height: 34px !important;
}
.icon-fa{
    font-family: "fontAwesome", "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.checkboxes
{
    display: flex;
    margin-top:30px;
    /* justify-content: space-between; */
}

.checkboxes .form-check
{
    display: flex;
    padding:0 10px;
}

.hideme
{
    visibility: hidden;
}



@media all and (max-width: 600px){
    #subprojeto {
       width: 100%; max-width: 100%;
       word-wrap: break-all;
    }
}
.swal2-container {
    z-index: 5000 !important;
}
.input-ferias {
    width: 80px;
    height: 30px;
}

.link-aba{
    cursor: pointer;
    background: none!important;
    border: none;
    padding: 8px!important;
    border-radius: 8px;
}

.link-aba:focus{
    outline:none;
}

.aba-esquerda{
    border-left: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
}

.aba-direita{
    border-right: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
}

.aba-active{
    border-width: 3px!important;
}

.bodyFolga{
    height: 265px;
    overflow:auto; 
    z-index: 100 !important;
}

.buttons-folga{
    padding-left: 0px;
    z-index: -1000 !important;
}

#add-folga{
    
    background-color: #720425;
    color: #fff;
}

#add-folga:hover, #add-folga:active, #add-folga:visited{
    background-color: #720425;
    color: #fff;
}

.add-remove-box{
    display: flex;
    flex-direction: row-reverse;
}

.react-datepicker-popper {
    z-index: 1000000 !important;
}

.wait {
    cursor: wait;
}