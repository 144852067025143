/* Criado por Carlos Eduardo em 03/01/2019 */
/* Em caso de dúvidas: contato.carlos@outlook.com */

.navbar-static-side {
  background-color: #2f4050;
  height: 100vh;
  position: fixed;
}

.navbar {
  padding-left: 15px;
}

.nav-label {
  font-size: 11px;
}

.nav-link {
  outline: none;
}

.nav-header {
  color: #DFE4ED;
}

.mini-navbar .welcome-logo {
  display: none;
}

li a {
  outline: none;
}

.logo-icon {
  width: 80%;
}

.logo-full {
  width: 92%;
}

.nav > li.active {
  border-left: 4px solid #4c1928;
}

.navbar-minimalize {
  background-color: #720425;
  border-color: #720425;
}

.navbar-minimalize:hover, .navbar-minimalize:active, .navbar-minimalize:focus, .navbar-minimalize:active:focus {
  background-color: #720425;
  border-color: #c60d45;
}

.nlt-logo {
  padding: 12px 0 0 10px;
  width: 75px;
}

/* Tentativa de resolver conflito com bootstrap 4, funcionando consideravelmente */
/* .nav { */
  /* display: block; */
/* } */
