.btn-aprovar,
.btn-aprovar:hover,
.btn-aprovar:focus {
    background-color: #720425;
    color: white;
}
.mt15 {
    margin-top: 15px;
}
.mr15 {
    margin-right: 15px;
}
.text15 {
    font-size: 15px;
}