.login-page {
  background-color: white !important;
}

.mytoogle input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.mytoogle label {
	cursor: pointer;
	text-indent: -9999px;
	width: 70px;
	height: 30px;
	background: rgba(26, 4, 37, 0.64);
	display: block;
	border-radius: 100px;
	position: relative;
}

.mytoogle label:after {
	content: '';
	position: absolute;
	top: 3px;
	left: 3px;
	width: 40px;
	height: 24px;
	background: #fff;
	border-radius: 90px;
	transition: 0.5s;
}

.mytoogle input:checked + label {
	background: #720425
}

.mytoogle input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

.mytoogle label:active:after {
	width: 130px;
}

.mytoogle {
	display: flex;
	justify-content: center;
	align-items: center;
}

.texttoggle {
  padding: 10px;
  margin-bottom: 5px;
}

.login-logo {
  width: 30%;
  max-width: 150px;
}

.up-part {
  padding-top: 10vh;
  padding-bottom: 8vh;
}

.login-logo {
	margin-top: 12px;
}

#btn-login{
	/* background: linear-gradient(90deg,#2f0743,#41295a); */
	background: #720425;;
	border: #720425;
}



#panel {
	width: 419px;
	-webkit-box-shadow: 0px 0px 14px 4px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 14px 4px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 14px 4px rgba(0,0,0,0.75);
}

@media only screen and (max-width: 600px) {
	#panel {
		width: 100%;
		margin: 10px;
	}
}

@media only screen and (max-width: 800px) {
	.wrapper {
		display: flex;
		justify-content: center;
	}
}